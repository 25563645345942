<template>
  <div class="d-flex flex-column flex-grow-1">
    <div class="d-flex flex-column">
      <div class="server-info">
        <v-text-field
          class="mr-4 adr"
          :label="$t('t.ServerAddress')"
          v-model="computedAddress"
          :rules="ruleRequiredMax200"
          :clearable="true"
        />
        <number-field
          class="port mr-4"
          :number.sync="computedPort"
          :label="$t('t.Port')"
        />
        <v-select
          class="encryption"
          :items="encryptionItems"
          item-text="text"
          item-value="id"
          :label="$t('t.Encryption')"
          v-model="computedEncryption"
        />
      </div>
      <v-switch
        dense
        :label="$t('t.DeleteIncomingMailFromServer')"
        v-model="computedDeleteMailFromServer"
      />
      <div class="d-flex flex-column">
        <v-text-field
          class="username mt-4"
          :label="$t('t.Username')"
          v-model="computedUsername"
          :rules="ruleRequiredMax200"
          :clearable="true"
        />
        <v-text-field
          class="password"
          :label="$t('t.Password')"
          v-model="computedPassword"
          :type="'password'"
          :rules="ruleRequiredMax200"
          :clearable="true"
        />
      </div>
    </div>
    <v-btn
      :disabled="!canTest"
      class="connect-btn mt-2"
      rounded
      justify-center
      color="primary"
      @click="testConnect()"
      v-ripple
      :loading="testConnectWorking"
    >
      <span>{{$t('t.TestConnection')}}</span>
    </v-btn>
    <v-bottom-sheet
      v-model="showError"
      persistent
      v-if="error"
      :hide-overlay="false"
    >
      <v-sheet
        class="text-center"
        height="200px"
      >
        <v-btn
          class="mt-6"
          color="error"
          @click="closeError()"
        >
          {{$t('t.Ok')}}
        </v-btn>
        <div class="py-3 h6">
          {{error.title}}
        </div>
        <div class="py-3 body-2">
          {{error.message}}
        </div>
      </v-sheet>
    </v-bottom-sheet>
  </div>

</template>

<script>

export default {
  components: {
    NumberField: () => import('@/components/number-field')
  },
  data () {
    return {
      encryptionItems: [
        { id: 'StartTLS_1_2', text: 'StartTLS 1.2' },
        { id: 'StartTLS_1_1', text: 'StartTLS 1.1' },
        { id: 'StartTLS_1_0', text: 'StartTLS 1.0' },
        { id: 'TLS_1_2', text: 'TLS 1.2' },
        { id: 'TLS_1_1', text: 'TLS 1.1' },
        { id: 'TLS_1_0', text: 'TLS 1.0' },
        { id: 'SSL3', text: 'SSL 3' },
        { id: 'SSL2', text: 'SSL 2' },
        { id: 'Unencrypted', text: this.$t('t.Unencrypted') }
      ],
      required: [v => (!!v && !this.lodash.isEqual(v, { id: null })) || this.$t('t.IsRequired')],
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument),
      dataProviderId: null,
      dataEncryptKey: null,
      dataAddress: null,
      dataCapabilities: null,
      dataDeleteMailFromServer: false,
      dataEncryption: null,
      dataPassword: null,
      dataPort: null,
      dataUsername: null,
      error: null,
      showError: false,
      testConnectWorking: false,
      passwordChanged: false
    }
  },
  computed: {
    ruleRequiredMax200 () {
      return [
        v => !!v || this.$t('t.IsRequired'),
        v => (v ?? '').length <= 200
      ]
    },
    computedDeleteMailFromServer: {
      get () {
        return this.dataDeleteMailFromServer
      },
      set (v) {
        this.dataDeleteMailFromServer = v
        this.dataDocumentEmit()
      }
    },
    computedAddress: {
      get () {
        return this.dataAddress
      },
      set (v) {
        this.dataAddress = v
        this.dataDocumentEmit()
      }
    },
    computedPort: {
      get () {
        return this.dataPort
      },
      set (v) {
        this.dataPort = v
        this.dataDocumentEmit()
      }
    },
    computedUsername: {
      get () {
        return this.dataUsername
      },
      set (v) {
        this.dataUsername = v
        this.dataDocumentEmit()
      }
    },
    computedPassword: {
      get () {
        return this.dataPassword
      },
      set (v) {
        this.passwordChanged = this.dataPassword !== v
        this.dataPassword = v
        this.dataDocumentEmit()
      }
    },
    computedEncryption: {
      get () {
        return this.dataEncryption || 'StartTLS_1_2'
      },
      set (v) {
        this.dataEncryption = v
        this.dataDocumentEmit()
      }
    },
    canTest () {
      if (!this.computedAddress || !this.computedPort) { return false }
      // if (this.isDirty) { return false }

      return this.computedPort?.toString().length > 0 &&
        this.computedAddress?.length > 0
    }
  },
  methods: {
    closeError () {
      this.showError = false
      this.error = null
    },
    emitDocument () {
      const doc = {
        ty: 'imap',
        v: {
          providerId: this.dataProviderId,
          encryptKey: this.dataEncryptKey,
          address: this.dataAddress,
          deleteEmailFromServer: this.dataDeleteMailFromServer,
          encryption: this.dataEncryption,
          password: this.dataPassword,
          port: this.dataPort,
          username: this.dataUsername,
          passwordChanged: this.passwordChanged
        }
      }

      if (!this.lodash.isEqual(this.value, doc)) {
        this.$emit('update:document', this.lodash.cloneDeep(doc))
      }
    },
    async testConnect () {
      this.testConnectWorking = true

      const s = this.document.v

      await this.$http().post('/core/providers/imap/test', s)
        .then(result => {
          const r = result.data
          if (r.hasError) {
            this.error = {
              title: this.$t('t.Snackbar.TestConnectionError'),
              message: r.message
            }
            this.showError = true
          } else {
            this.$store.dispatch('showSuccessSnackbar', this.$t('t.Snackbar.SuccessConfirmation', { title: this.$t('t.TestConnection') }))
          }
        })
        .catch(e => this.$store.dispatch('showErrorSnackbar', e.response?.data?.message))
        .finally(e => {
          this.testConnectWorking = false
        })
    }
  },
  props: {
    clearable: {
      type: Boolean,
      default: false
    },
    isDirty: {
      type: Boolean,
      default: false
    },
    document: Object
  },
  watch: {
    document: {
      immediate: true,
      handler (d) {
        const v = d?.v
        this.dataProviderId = v?.providerId
        this.dataEncryptKey = v?.encryptKey
        this.dataAddress = v?.address
        this.dataCapabilities = v?.capabilities
        this.dataEncryption = v?.encryption || 'StartTLS_1_2'
        this.dataPassword = v?.password
        this.dataPort = v?.port
        this.dataUsername = v?.username
        this.dataDeleteMailFromServer = v?.deleteEmailFromServer
      }
    },
    isDirty: {
      immediate: true,
      handler (v) {
        if (!v) { this.passwordChanged = false }
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.server-info
  display grid
  grid-template-columns 70% 10% auto
  grid-template-areas 'adr port encrypt'

.adr
  grid-area adr

.port
  grid-area port

.encryption
  grid-area encrypt

.connect-btn
  max-width 300px

.username, .password
  max-width 30%
</style>
